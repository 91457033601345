import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./about.scss"
import Img from "gatsby-image" // add some style if you want!

export default function About({ data: { allImageContent } }: { data: any }) {
  const img = allImageContent.edges.find(
    (element: any) => element.node.fluid.originalName === "my-portrait.jpg"
  )

  return (
    <Layout>
      <SEO
        title="Super Productivity – To do list & time tracker for programmers & designers"
        description="Free to do list & time tracker for programmers & designers with build-in Jira & Github Integrations. Download for free or hack it for yourself."
      />

      <Img className="portrait" fluid={img.node.fluid} />

      <article className="container-small">
        <p>
          Hello! I am Johannes. I am the main author of this app. I have been
          working as a freelance web developer for over a decade now. While
          initially programming was more of a part time thing to earn money, I
          can now say with confidence that I really do love my job.
        </p>
        <p>
          Super Productivity came to life when I grew annoyed by the
          repetitiveness and dullness of tracking my time to Jira Issues.
          Programmers don not like repetitiveness as avoiding it tends to be a
          big part of the job.
        </p>
        <p>
          The initial prototype just took me a week to build. Back then I would
          have never thought that this would grew into a complex multi-language
          app with thousands of users and many other people contributing to it.
        </p>
        <p>
          Even though this is a non profit project I spend almost all my free
          time on it. It really gives me a lot of joy to use as my very own app
          everyday. To see that I have built something useful, not just for me,
          but many others, makes me happy.
        </p>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 250) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`
